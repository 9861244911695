import React from 'react';
import './progressBar.scss';

function ProgressBar(props) {
  const { width, bgColor } = props;
  return (
    <div className="ProgressBar">
      <span className="ProgressBar__Indicator" style={{ width: `${width}%` }}>
        <span
          className="ProgressBar__Indicator--Fill"
          style={{ backgroundColor: bgColor }}
        ></span>
      </span>
    </div>
  );
}

export default ProgressBar;
