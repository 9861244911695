import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { GET, makeApiCal } from '../../utils';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/home.scss';

import Loader from '../Loader/Loader';

import HomeSlide from './Slides/HomeSlide';
import StatsSlide from './Slides/StatsSlide';
import RewardSlide from './Slides/RewardSlide';
import ToolsSlide from './Slides/ToolsSlide';
import MilestoneSlide from './Slides/MilestoneSlide';
import WelcomeSlide from './Slides/WelcomeSlide';

import SliderButton from '../../Resources/images/back.svg';

function Home() {
  const sliderRef = useRef(null);
  const [err, catchError] = useState(false);
  const [apiData, setApiData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const action = {
      method: GET,
      route: 'v2/course/campaign/homeCard',
      storeData: setApiData,
      catchError: catchError,
    };
    makeApiCal(action);
  }, []);

  useEffect(() => {
    if (apiData) {
      setLoading(false);
      // setTeamId(apiData?.campaignOrg?.teamId);
    }
  }, [apiData]);

  // useEffect(() => {
  //    if (teamId) localStorage.setItem('TeamId', teamId);
  // }, [teamId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    touchThreshold: 500,
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="Home">
      <Slider {...settings} ref={sliderRef}>
        <HomeSlide data={apiData?.homeCard} />
        <StatsSlide data={apiData?.statCard} />
        <RewardSlide data={apiData?.awardsCard} />
        <ToolsSlide data={apiData?.toolsCard} />
      </Slider>
      <div className="Home__SliderButton">
        <img
          onClick={() => sliderRef.current.slickPrev()}
          className="Home__SliderButton--Prev"
          src={SliderButton}
          alt=">"
        />
        <img
          onClick={() => sliderRef.current.slickNext()}
          className="Home__SliderButton--Next"
          src={SliderButton}
          alt=">"
        />
      </div>
    </div>
  );
}

export default Home;
