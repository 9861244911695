import React from 'react';
import ProgressBar from '../UI/ProgressBar/ProgressBar';

import trophy from '../../Resources/images/TrophyThumb.svg';
import Coin from '../../Resources/images/Coin.svg';

function Drawer(props) {
  const { data } = props;

  return (
    <div
      className="Drawer"
      style={{ borderColor: data?.bgColor, backgroundColor: data?.bgColor }}
    >
      <div className="Drawer__Top">
        <div className="Drawer__Top__Profile">
          <img
            className="Drawer__Top__Profile--Img"
            src={data?.logo || "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/campaign/Icons/defaultLogo.svg"}
            alt="LOGO"
          />
          <span className="Drawer__Top__Profile--Name"> {data?.orgName}</span>
        </div>
        <div className="Drawer__Top__Points">
          <span className="Drawer__Top__Points--Count">{data?.points} pts</span>
          <img src={Coin} alt="o" />
        </div>
      </div>
      <div className="Drawer__Body">
        <div className="Drawer__Body__Stats">
          <div className="Drawer__Body__Stats--Earning">{data?.earnedGmv}</div>
          <ProgressBar
            bgColor={data?.progressBarColor}
            width={data?.percentage}
          />
          <div className="Drawer__Body__Stats--Goal">Goal: {data?.goal}</div>
        </div>
      </div>
      {/*<div className="Drawer__Button" onClick={data?.hanleClick}>
            <img src={trophy} alt="" />
            <span className="Drawer__Button--Text">Your Rewards</span>
      </div>*/}
    </div>
  );
}

export default Drawer;
