import React from 'react';
// import IMAGE_BASE_URL from '../../utils/constant';
// import RevenueIcon from '../../images/RevenueGoal.svg';

function Revenue({ data, handleClick }) {
  const trophy =
    'https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/campaign/Symbols/trophy.svg';
  return (
    <div
      className="MilestoneRevenue"
    >
      <div className="MilestoneRevenue__Top">
        <div className="MilestoneRevenue__Top__Col1">
          <div className="MilestoneRevenue__Top__Col1--Label">
            {' '}
            <img loading="lazy" src={data && data.icon} alt="icon" />
            Today’s Revenue
          </div>

          {data && data.rank && data.rank.text && (
            <div
              className="MilestoneRevenue__Top__Col1--Rank"
              style={{
                backgroundColor: data && data.rank && data.rank.bgColor,
                color: data && data.rank && data.rank.color,
              }}
            >
              {data.rank.text}
            </div>
          )}
        </div>
        <div className="MilestoneRevenue__Top__Col2">
          <div className="MilestoneRevenue__Top__Col2--Amount">
            ₹ {parseInt(data && data.gmv).toLocaleString('en-IN')}
          </div>
          <div className="MilestoneRevenue__Top__Col2--Milestone">
            {data?.isComplete ? "Congrats! 🎉" : <React.Fragment>
              Next Milestone : ₹{' '}
              {parseInt(data && data.nextMilestone).toLocaleString('en-IN')}{' '}
            </React.Fragment>}
          </div>
        </div>
      </div>
      {data && data.showLeaderBoard && (
        <div
          className="MilestoneRevenue__Bottom"
          onClick={() => handleClick('/leaderboard')}
        >
          <div className="MilestoneRevenue__Bottom--Left">
            <img loading="lazy" src={trophy} alt="check" />
            View leaderboard
          </div>

          <div className="Text__Arrow">&rarr;</div>
        </div>
      )}
    </div>
  );
}

export default Revenue;
