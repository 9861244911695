const baseUrl_devTesting = "http://store-sarthak.dev.classplus.co";
const baseUrl_preprod = "https://store.preprod.classplus.co";
const baseUrl_prod = "https://api.classplusapp.com";

// FOR ANY CHANGES IN TARGET, CHANGE THE BELOW VALUE
const base_Url = baseUrl_prod;

// CONSTANT
export const SOURCE_HOME = "SOURCE_HOME";
export const SOURCE_WEBVIEW = "SOURCE_WEBVIEW";

export const GET = "GET";
export const POST = "POST";

// FUNCTION TO CAPTURE QUERY PARAMETERS, initialized by '?', separated by '&'
export const getQuery = () => {
	let b = window.location.search
		.slice(1)
		.split("&")
		.map((qStr) => qStr.split("="))
		.reduce((acc, inc) => {
			acc[inc[0]] = inc[1];
			return acc;
		}, {});
	return b;
};

const token = getQuery()["token"] || localStorage.getItem("campaignToken");
// const token =
//   'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwib3JnSWQiOjEsIm5hbWUiOiJNYXN0ZXIgVHV0b3IiLCJlbWFpbCI6InNhazIyMkBnbWFpbC5jb20iLCJtb2JpbGUiOiI5MTk4NzEyNTE4MDUiLCJ0eXBlIjozLCJpYXQiOjE2Mjk3NTYxMzMsImV4cCI6MTYzMTQ4NDEzM30.ZAX1OBbWw8ICSOoYCZ3ggRqjrma448DIfx3cFQix5CWRzwueVC2jm7IOE5AhJ9NI';
// API CALL FUNCTION
export const makeApiCal = (action) => {
	// PARAMETERS - method, route, storeData, catchError, body
	switch (action.method) {
		case GET:
			return fetch(`${base_Url}/${action.route}`, {
				method: GET,
				headers: {
					"x-access-token": token,
					"Content-Type": "application/json",
					Connection: "keep-alive",
				},
			})
				.then((res) => res.json())
				.then((res) => {
					res?.status === "success"
						? action.storeData(res?.data)
						: action.catchError(res.message);
				})
				.catch((err) => {
					action.catchError(err);
				});
		case POST:
			return fetch(`${base_Url}/${action.route}`, {
				method: POST,
				headers: {
					"x-access-token": token,
					"Content-Type": "application/json",
					Connection: "keep-alive",
				},
				body: JSON.stringify(action.body),
			})
				.then((res) => res.json())
				.then((res) => {
					res?.status === "success"
						? action.storeData(res?.data)
						: action.catchError(res.message);
				})
				.catch((err) => action.catchError(err));
		default:
			return;
	}
};
// DEEPLINK FUNCTION(s)
// Takes in single parameter, a string of 3 values i.e. `SCREEN_NAME,paramOne,paramTwo`

// WEBVIEW
export const directDeepLink = (url, source, showAlert) => {
	const action = {
		method: POST,
		route: "v2/storeDeeplink",
		body: { deeplink: url },
		storeData: (e) => console.log(e),
		catchError: (e) => console.error(e),
	};
	switch (source) {
		case SOURCE_HOME:
			return () => {
				window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
					JSON.stringify(url)
				);
				window?.mobile?.exceuteDeeplink(JSON.stringify(url));

				makeApiCal(action);
			};
		case SOURCE_WEBVIEW:
			return () => {
				window?.webkit?.messageHandlers?.onDeeplinkExecuted?.postMessage(
					`${url?.screen},${url?.paramOne},${url?.paramTwo},${url?.paramThree}`
				);

				window?.mobile?.onDeeplinkExecuted(
					`${url?.screen},${url?.paramOne},${url?.paramTwo},${url?.paramThree}`
				);

				window?.mobile?.onDeeplinkExecutedV2(JSON.stringify(url));

				makeApiCal(action);
			};
		default:
			return;
	}
};

// EXPORTING YOUTUBE STARTER PACK CARD DETAILS FOR TOOLS SECTION (EXCEPTION: HANDLING ON FRONTEND)
export const staticYoutubeData = {
	text: "YouTube Starter Pack",
	subtext: "Start your YouTube journey & grow like never before!",
	icon: "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/campaign/Icons/youtube.svg",
	arrow:
		"https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/campaign/Arrows/arrow-right.svg",
	bgColor: "#E14B59",
	bgImage:
		"https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/campaign/Graphics/YouTubeStarterPackCard.svg",
	deeplink: {
		screen: "SCREEN_DYNAMIC",
		paramOne: "SCREEN_DYNAMIC",
		paramTwo: "YouTube Starter Pack",
		paramThree: `query ($token: String!) {withAuth(token: $token) {cards:dynamicNestedScreen(type: "YTStarterDynamicScreen") {cacheKey    query    order    type {name} }}}`,
		paramSource: "",
		paramTracking: "StoreYouTubeStarterPack",
	},
};
