import React from 'react';
import './styles/Milestone.scss';

import Checkpoints from './Checkpoints';
import Revenue from './Revenue';

function MilestonePage(props) {
  const { data, swipeActions } = props;

  // console.log(data);

  return (
    <div className="Milestone fadeIn" {...swipeActions}>
      <Revenue data={data?.revenue} />
      <Checkpoints data={data?.mileStones} />
    </div>
  );
}

export default MilestonePage;
