import React from 'react';

import './tools.scss';

import ToolCard from '../../Tools/ToolCard';
import { staticYoutubeData } from '../../../utils';

function ToolsSection(props) {
  const { data } = props;

  return (
    <div className="ToolsSection">
      {data?.map((data, i) => {
        return i === 0 ? <ToolCard data={staticYoutubeData} key={i} /> : <ToolCard data={data} key={i} />;
      })}
    </div>
  );
}

export default ToolsSection;
