import React from 'react';
import './RevenueCard.scss';

import ProgressBar from '../../UI/ProgressBar/ProgressBar';

import Coin from '../../../Resources/images/Coin.svg';

function RevenueCard(props) {
  const { data, changeRoute, handleClick } = props;

  return (
    <div className="RevenueCard" onClick={handleClick}>
      <div
        className="RevenueCard__Top"
        style={{ backgroundImage: `url(${data?.cardBgImage})` }}
      >
        <div className="RevenueCard__Top--Label">YOUR REVENUE</div>
        <div className="RevenueCard__Head">
          <div className="RevenueCard__Head--Logo">
            <div className="RevenueCard__Head--Logo--Text">{data?.total}</div>
          </div>
        </div>

        <ProgressBar
          width={data?.progressBar}
          bgColor={data?.progressBarColor}
        />

        <div className="RevenueCard__New_Footer">
          <div className="RevenueCard__New_Footer__left">
            {data?.completedPercent}
          </div>
          <div className="RevenueCard__New_Footer__right">
            Goal: {data?.goal}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RevenueCard;
