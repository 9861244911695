import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';
import StatsSection from '../StatsSection/StatsSection';
import CardFooter from '../CardFooter/CardFooter';

import BannerImage from '../../../Resources/images/Points.png';

function StatsSlide(props) {
  const { data } = props;

  const banner = {
    text: 'Teach more students & Earn points',
    image: BannerImage,
    gridTemplateColumns: "2fr 1fr",
  };

  const footer = {
    text: null,
    button: {
      text: 'See all tasks',
      bgColor: 'rgba(255, 255, 255, 1)',
    },
    deepLink: data?.deeplink,
  };

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#071D32',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <PeaknStatsCard data={banner} />
      <StatsSection data={data?.stats} />
      <CardFooter data={footer} />
    </div>
  );
}

export default StatsSlide;
