import React from 'react';
import { directDeepLink, SOURCE_HOME } from '../../../utils';

function CardFooter(props) {
  const { data } = props;

  return (
    <div className="CardFooter">
      <div className="CardFooter__Text">{data?.text}</div>
      <div
        onClick={directDeepLink(data?.deepLink, SOURCE_HOME)}
        className="CardFooter__Button"
        style={{ backgroundColor: data?.button?.bgColor }}
      >
        {data?.button?.text}
      </div>
    </div>
  );
}

export default CardFooter;
