import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';

import BannerImage from '../../../Resources/images/tools1.png';
import ToolsSection from '../ToolsSection';

function ToolsSlide(props) {
  const { data } = props;

  const banner = {
    text: 'Tools to increase your impact',
    image: BannerImage,
    gridTemplateColumns: "1.3fr 1fr",
  };

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#1E3D7E',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <PeaknStatsCard data={banner} />
      <ToolsSection data={data} />
    </div>
  );
}

export default ToolsSlide;
