import React from 'react';

import PointsCard from '../PointsCard';
import LightsImage from "../../../Resources/images/Winter.png";
import PeaknStatsCard from '../PeakAndStatsCard';
import RevenueCard from '../RevenueCard';
import CardFooter from '../CardFooter/CardFooter';

function HomeSlide(props) {
  const { data } = props;
  const banner = {
    text: "Winter Sale Celebration",
    image: LightsImage,
    gridTemplateColumns: "9fr 11fr",
  };

  const footer = {
    text: data?.activeOrgs,
    button: {
      text: "View leaderboard",
      bgColor: "rgba(255, 255, 255, 1)",
    },
    deepLink: data?.deeplink,
  };

  const points = [data?.rankCard, data?.pointsCard];

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#0A307B',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
      }}
    >
      <PeaknStatsCard data={banner} />
      <RevenueCard data={data?.revenueCard} />
      <PointsCard data={points} />
      <CardFooter data={footer} />
    </div>
  );
}

export default HomeSlide;
