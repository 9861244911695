import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { GET, getQuery, makeApiCal } from '../../utils';
import { useSwipeable } from 'react-swipeable';
import './layout.scss';

import Content from './Content';
import Drawer from './Drawer';
import Navbar from './Navbar';
import ContentLoader from '../Loader/ContentLoader';
import DrawerShimmer from '../Loader/DrawerShimmer';

function Layout(props) {
  const history = useHistory();
  let navRef = useRef();
  const [apiData, setApiData] = useState();

  const [nav, setNav] = React.useState(true);

  const navBar = [
    {
      key: 'leaderboards',
      name: 'Leaderboard',
      drawerColor: '#2B0D2C',
    },
    {
      key: 'tasks',
      name: 'Tasks',
      drawerColor: '#071D32',
    },
    {
      key: 'rewards',
      name: 'Rewards',
      drawerColor: '#4F1D6B',
    },
    {
      key: 'tools',
      name: 'Tools',
      drawerColor: '#1E3D7E',
    },
  ];
  // useEffect(() => {
  //   console.log(navBar);
  // }, [navBar]);

  const [activeTab, updateActiveTab] = useState();
  const [activeIndex, updateActiveIndex] = useState();

  const [loading, updateLoading] = useState(true);
  const [err, catchError] = useState(false);

  const getData = () => {
    const action = {
      method: GET,
      route: 'v2/course/campaign/teachersDayInfo',
      storeData: setApiData,
      catchError: catchError,
    };
    makeApiCal(action);
  };

  useEffect(() => {
    getData();

    const tab = props?.match?.params?.tab;

    const scroller = new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('S2');
        scrollNav(3);
        resolve();
      }, 1000);
    });

    scroller
      .then(() => {
        setTimeout(() => {
          scrollNav(-3);
          console.log('S3');
        }, 500);
      })
      .then(() => {
        if (tab === 'milestones2' || tab === 'rewards' || tab === 'tools') {
          setTimeout(() => {
            scrollNav(3);
            console.log('S4');
          }, 1000);
        }
      });
  }, []);

  useEffect(() => {
    const tab = props?.match?.params?.tab;
    if (apiData) {
      updateLoading(false);
      if (tab) {
        updateActiveTab(...navBar?.filter((item) => item.key === tab));
      }
    }
  }, [apiData, err]);

  useEffect(() => {
    if (activeTab) {
      history.push(`/details/${activeTab?.key}`);
      updateActiveIndex(
        navBar.findIndex((item) => item?.key === activeTab?.key)
      );
    }
  }, [activeTab]);

  const drawerData = {
    ...apiData?.campaignOrg,
    bgColor: activeTab?.drawerColor,
    hanleClick: () => history.push('/rewards'),
  };

  const changeTab = (value) => {
    if (activeIndex === navBar?.length - 1 && value > 0) {
      updateActiveTab(navBar[0]);
      scrollNav(-3);
    } else if (activeIndex === 0 && value < 0) {
      updateActiveTab(navBar[navBar?.length - 1]);
      scrollNav(3);
    } else {
      updateActiveTab(navBar[activeIndex + value]);
      scrollNav(value);
    }
  };

  const scrollNav = (value) => {
    const scrollWidth = window?.innerWidth * (Math.abs(value) * 0.34);
    console.log(scrollWidth)
    if (value > 0) {
      navRef?.current?.scrollBy(scrollWidth, 0);
    } else if (value < 0) {
      navRef?.current?.scrollBy(-scrollWidth, 0);
    }
  };

  const swipeActions = useSwipeable({
    onSwipedLeft: () => changeTab(1),
    onSwipedRight: () => changeTab(-1),
  });

  return loading ? (
    <div className="Layout">
      <DrawerShimmer data={drawerData} />
      {nav && <Navbar
        data={navBar}
        activeTab={activeTab}
        updateActiveTab={updateActiveTab}
        scrollNav={scrollNav}
        ref={navRef}
      />}
      <div {...swipeActions}>
        <ContentLoader />
      </div>
    </div>
  ) : (
    <div className="Layout">
      <Drawer data={drawerData} />
      {nav && <Navbar
        data={navBar}
        activeTab={activeTab}
        updateActiveTab={updateActiveTab}
        scrollNav={scrollNav}
        ref={navRef}
      />}
      <Content
        data={apiData}
        activeTab={activeTab}
        swipeActions={swipeActions}
        callMainApi={getData}
        setNav={setNav}
      />
    </div>
  );
}

export default Layout;
