import React from 'react';
import './Leaderboard.scss';

import Ranking from './Ranking';

function Leaderboard(props) {
  const { data, swipeActions } = props;

  return (
    <div className="Leaderboard fadeIn" {...swipeActions}>
      <div className="Leaderboard__Body">
        <div className="Leaderboard__Heading">Ranking</div>
        <div className="Leaderboard__Subheading">
          Know where you stand among other institutes
        </div>

        <Ranking data={data?.teamRankings} />

        {data?.awardsImage?.imgUrl ? (
          <img
            className="Leaderboard__Body--Prize"
            src={data?.awardsImage?.imgUrl}
            width="100%"
            alt=""
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default Leaderboard;
